import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import { environment } from '@env/outdoorPro/environment';
import { Platform } from '@ionic/angular';
import { Logger } from '@lib/core';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ToastService } from '@lib/shared/services/toast.service';
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency';
const log = new Logger('App');
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@Sentry.TraceClassDecorator()
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private platform: Platform,
    private analytics: AngularFireAnalytics,
    private toastService: ToastService
  ) {}

  @Sentry.TraceMethodDecorator()
  async ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => route.data)
      )
      .subscribe({
        next: (event) => {
          const title = event.title;
          if (title) {
            this.titleService.setTitle(this.translateService.instant(title));
          }
        },
        error: (error) => {
          console.error(error);
          this.toastService.showDissmissableErrorToast('Error setting page title');
        },
      });

    // Cordova platform and plugins initialization
    await this.platform.ready();
    this.onCordovaReady();
  }

  private async onCordovaReady() {
    log.debug('device ready');

    if (Capacitor.isNativePlatform()) {
      log.debug('Cordova init');

      await Keyboard.setAccessoryBarVisible({ isVisible: true });
      this.setStatusBarStyleLight();
      await this.readTrackingPermission();
    }
  }

  async setStatusBarStyleLight() {
    await StatusBar.setStyle({ style: Style.Light });
  }

  private win(res: any) {
    this.analytics.setAnalyticsCollectionEnabled(true);
  }

  private fail(res: any) {
    this.analytics.setAnalyticsCollectionEnabled(false);
  }

  private async askTrackingPermission() {
    if (this.platform.is('ios')) {
      const { status } = await AppTrackingTransparency.requestPermission();
      if (status === 'authorized') {
        this.win(status);
      } else {
        this.fail(status);
      }
    }
  }

  private async readTrackingPermission() {
    if (this.platform.is('ios')) {
      const { status } = await AppTrackingTransparency.getStatus();
      if (status === 'authorized') {
        this.win(status);
      } else if (status === 'denied') {
        this.fail(status);
      } else {
        await this.askTrackingPermission();
      }
    } else {
      this.win('not ios');
    }
  }
}

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: true,
  envName: 'production',
  hmr: false,
  version: env.npm_package_version,
  name: 'OUTDOOR PRO',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US'],
  appType: 'outdoor',
  serverUrl: 'https://outdoorproapp.com',
  firebase: {
    apiKey: 'AIzaSyBV61lm5wG8yMu9Kd8rLAVDZWV_PWKkMIA',
    authDomain: 'ekc-app.firebaseapp.com',
    databaseURL: 'https://ekc-app.firebaseio.com',
    projectId: 'ekc-app',
    storageBucket: 'ekc-app.appspot.com',
    messagingSenderId: '288907562496',
    appId: '1:288907562496:web:b78fb713ca58a3688d52e5',
    measurementId: 'G-3KJGRSS3CB',
  },
  csvBox: {
    apiKey: 'YyDEemnX2lXvhBAFyXH9LBqrZWLJHX',
  },
  sentryDSN: 'https://4245a83175d63a45173532e8965668d2@o440945.ingest.sentry.io/4505696361381888',
  previewUrlsRegex: /^https:\/\/(outdoorpro|outdoorpro-staging)--.*\.web\.app/,
  stagingUrlsRegex: /^https:\/\/.*\.outdoorpro\.app/,
  productionUrls: ['https://outdoorpro.app'],
  sentryProject: 'outdoor-pro',
  cloudflareVideoUrl: 'https://customer-w24ytoxcxz7ffbty.cloudflarestream.com',
  configCatSDK: '_aPbCFYpVEqKc3IjdtsJ4w/fb7grccpWk6e4Sui37JcSQ',
};

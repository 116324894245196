<app-header [showBack]="false">
  <ion-button
    *ngIf="credentialsService.userRole?.permissions.editContent"
    button
    color="light"
    class="ion-margin-end"
    slot="end"
    routerLink="/tabs/home/edit-homepage"
    >Edit</ion-button
  >
</app-header>
<ion-content>
  <div class="homepage-wrapper">
    <div class="ion-margin-bottom">
      <div class="ion-text-center">
        <h1>Hey {{ credentialsService.userData?.name }}!</h1>
        <p *ngIf="this.credentialsService.userLicence?.trial">
          Your trial finishes on {{ credentialsService.userLicence?.expiresAt.toDate() | date: 'dd/MM/yyyy' }}
        </p>
      </div>

      <div class="ion-text-center ion-margin-top ion-margin-bottom">
        <img class="home-logo" src="assets/pe-pro-logo.png" alt="PE Pro Logo" />
      </div>

      <ion-text color="primary" *ngIf="(home | async)?.mainContent">
        <div class="ion-text-center" [innerHTML]="(home | async)?.mainContent"></div>
      </ion-text>

      <div class="ion-text-center ion-margin-top ion-margin-bottom sports">
        <h2>Social media</h2>
        <div class="social-media">
          <a (click)="openPage('https://www.facebook.com/profile.php?id=100090667205349')">
            <ion-icon name="logo-facebook"></ion-icon>
          </a>
          <a (click)="openPage('https://twitter.com/Outdoor_Pro_App')">
            <ion-icon name="logo-twitter"></ion-icon>
          </a>
          <a (click)="openPage('https://www.instagram.com/outdoorpro_app/')">
            <ion-icon name="logo-instagram"></ion-icon>
          </a>
          <a (click)="openPage('https://www.linkedin.com/in/outdoor-pro-107b632a8/')">
            <ion-icon name="logo-linkedin"></ion-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</ion-content>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditContentGuard } from '@lib/shared/guards/editContent.guard';
import { Shell } from '../shell/shell.service';

import { HomeComponent } from './home.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    {
      path: 'home',
      children: [
        { path: '', component: HomeComponent, data: { title: 'Home' } },
        {
          path: 'edit-homepage',
          loadChildren: () => import('./edit-home/edit-home.module').then((m) => m.EditHomeModule),
          canActivate: [EditContentGuard],
        },
      ],
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class HomeRoutingModule {}

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/outdoorPro/app.module';
import { environment } from '@env/outdoorPro/environment';
import * as Sentry from '@sentry/angular-ivy';
import {
  CaptureConsole as CaptureConsoleIntegration,
  HttpClient as HttpClientIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from '@sentry/integrations';

/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

Sentry.init({
  dsn: environment.sentryDSN,
  beforeSend(event, hint) {
    if (hint.originalException.toString().indexOf('FirebaseError: Missing or insufficient permissions') > -1) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
    }
    return event;
  },
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new CaptureConsoleIntegration({
      levels: ['error'],
    }),
    new HttpClientIntegration(),
    new ReportingObserverIntegration({
      types: ['crash', 'deprecation', 'intervention'],
    }),
    new Sentry.BrowserProfilingIntegration(),
  ],
  tracePropagationTargets: [
    'localhost',
    ...environment.productionUrls,
    environment.stagingUrlsRegex,
    environment.previewUrlsRegex,
  ],
  enableTracing: true,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  release: `${environment.sentryProject}@${environment.version}`,
  environment: environment.envName,
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
